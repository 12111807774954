<template>
  <div v-if="content" @click="showContent(content)" style="position: relative; margin-top: 2px;">
    <div class="thumbnail-image">
      <div :style="'background-image: url(' + imagePath(content.images) + ');'"></div>
    </div>
    <div class="thumbnail-text">
      <div class="thumbnail-category">
        <div :class="'icon-' + iconType(content.type)"></div>
        <h3>{{ itemType(content.type) }}</h3>
      </div>
      <div :class="'thumbnail-heading' + titleBG">
        <h2>{{ contentTitle(content) }}</h2>
      </div>
    </div>
    <div class="thumbnail-category thumbnail-reason">
      <h3>{{ reason }}</h3>
    </div>
  </div>
</template>

<script>
import contentMixin from '@/mixins/content.js'
export default {
  name: 'AssociatedCont',
  mixins: [contentMixin],
  props: {
    content: { default: null },
    reason: { default: null }
  },
  computed: {
    firebasePath() {
      return this.$store.getters.getFirebasePath
    },
  },
  methods: {
    contentType(cont) {
      if (cont.type == "event") {
        return null
      }
      return cont.type
    },
    getContent(cont) {
      return cont || "<p>Content to be Added</p>"
    },
    imagePath(images) {
      if (Array.isArray(images) && images[0]) {
        return this.firebasePath + 'content%2F' + escape(images[0]) + '?alt=media'
      } else {
        return this.firebasePath + 'content%2Fempty16x9.png?alt=media'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail-text {
  position: absolute;
  bottom: 0;
  text-align: left;
  pointer-events: none;
}
.thumbnail-heading {
  display: flex;
  justify-content: left;
  width: fit-content;
  padding: 0.4em 0.4em 0 0.4em;
  color: var(--black);
}
.thumbnail-category {
  display: flex;
  width: fit-content;
  padding: 0.2em 0.4em 0 0.3em;
  color: var(--white);
  background-color: var(--black);
  h3 {
    margin-left: 0.2em;
    margin-bottom: -0.1em;
  }
}
.thumbnail-reason {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}
.thumbnail-image {
  overflow: hidden;
}
.thumbnail-image div {
  width: 100%;
  height: 10em;
  background-size: cover;
  background-position: center;
  filter: grayscale(50%) sepia(50%) brightness(0.75);
  cursor: pointer;
  transition: transform 0.25s, filter 0.25s;
}
.thumbnail-image:hover div {
  filter: none;
  transform: scale(1.05);
}
</style>
<template>
  <div class="acknowledgement" :class="{ 'hidden': !show }" @click="hide">
    <div class="paragraph">
      
      <p>We acknowledge the stories, traditions and living cultures of Aboriginal and Torres Strait Islander peoples on this land and commit to listen deeply to Story and be respectful of Country in our collaborations with First Peoples.</p>
      <p>Aboriginal and Torres Strait Islander visitors are advised that this site contains images and names of deceased persons.</p>
      <button>
        <h2>CONTINUE</h2>
      </button>
    </div>
  </div>
  <div class="home" @click="enterWebsite">
    <img class="conviction-politics-logo" src="../assets/conviction-politics.svg">
    <div class="paragraph">
      <h1>Trade Unionism and Democracy did not start with Eureka or the strikes of the 1890s. They landed on our shores shackled in chains.</h1>
    </div>
    <button class="explore-button">
      <h1>EXPLORE</h1>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      show: true
    };
  },
  methods: {
    hide() {
      this.show = false;
    },
    enterWebsite() {
      this.$router.push('/stories');
    }
  }
}
</script>

<style lang="scss" scoped>
.acknowledgement {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000BB;
  z-index: 1;
  pointer-events: auto;
  transition: opacity 0.5s;
}
.home {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  background: var(--black) url(@/assets/background.png) center no-repeat;
  background-size: cover;
  position: relative;
}
.conviction-politics-logo {
  width: clamp(18em, 90vw, 44em);
  margin-top: 8em;
  margin-bottom: 4em;
  filter: drop-shadow(0 0 2em #000000)
          drop-shadow(0 0 1em #000000);
}
.paragraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  width: 90%;
  font-size: clamp(14px, 4vw, 18px);
  text-align: center;
  color: var(--white);
  pointer-events: auto;
  p {
    max-width: 30em;
    filter: drop-shadow(0 0 1em #000000)
            drop-shadow(0 0 0.5em #000000);
  }
  h1 {
    max-width: 18em;
    filter: drop-shadow(0 0 0.5em #000000)
            drop-shadow(0 0 0.25em #000000);
  }
}
button {
  width: 15em;
  color: var(--black);
  background-color: var(--brown);
  padding: 0.75em 2em 0.25em;
  border: none;
  transition: 0.1s;
  h2 {
    font-size: 2.25em;
  }
}
.explore-button {
  width: clamp(13em, 50%, 22em);
  padding: 1.25em 2em 0.25em;
  margin-top: 4em;
  margin-bottom: 8em;
  filter: drop-shadow(0 0 1em #000000)
          drop-shadow(0 0 1em #000000);
  h1 {
    font-size: clamp(2em, 10vw, 3.5em);
  }
}
button:hover {
  background-color: var(--white);
}
.hidden {
  opacity: 0;
  pointer-events: none;
}
</style>
import Vue from 'vue'
import { Store } from 'vuex';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default {
  state: {
    loading: false,
    selectedContent: null,
    showLongRead: null,
    showPerson: null,
    showShortRead: null,
    showDocumentary: null,
    content: null,
    people: null,
    movements: null,
    movementList: [
      { id: 1, name: 'All Movements' },
      { id: 2, name: 'Political Protest' },
      { id: 3, name: 'Industrial Protest' },
      { id: 4, name: 'Rural Protest' },
      { id: 5, name: 'Convict Protest' },
      { id: 6, name: 'Absconding and Bushranging' },
      { id: 7, name: 'Indigenous Resistance' },
      { id: 8, name: 'Empire Builders' }
    ],
    locations: null,
    searchQuery: null,
    shuffle: false,
    typeFilter: null,
    themeFilter: null,
    sortType:null,
    columns:["Author","Theme","Media Type"],
    movementFilter: null,
    myFilter: null,
    zoom: 10,
    navDisplay: null,
    convictLastNames: null,
    letterSelected: "A",
    showHolding: false,
    showShare: false,
    showReorder: false,
    allowSignUp: false,
    currentArticles: "",
    editContent: null,
    decades:null
  },
  mutations: {
    setEditContent(state, payload) {
      console.log("EDIT UPDATE", payload)
      state.editContent = payload
    },
    setCurrentArticles(state, payload) {
      console.log("setCurrentArticles", payload)
      state.currentArticles = payload
    },
    setShowShare(state, payload) {
      console.log("setShowShare", payload)
      state.showShare = payload
    },
    setShowReorder(state, payload) {
      state.showReorder = payload
    },
    setShowHolding(state, payload) {
      state.showHolding = payload
    },
    setAllowSignUp(state, payload) {
      state.allowSignUp = payload
    },
    setLetterSelected(state, payload) {
      state.letterSelected = payload
    },
    setConvictLastNames(state, payload) {
      state.convictLastNames = payload
    },
    setNavDisplay(state, payload) {
      state.navDisplay = payload
    },
    doZoom(state, payload) {
      state.zoom += payload
    },
    setZoom(state, payload) {
      state.zoom = payload
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload
    },
    setReshuffle(state, payload) {
      state.shuffle = payload
    },
    setTypeFilter(state, payload) {
      state.typeFilter = payload
    },
    setMyFilter(state, payload) {
      console.log("FILTER SET", payload)
      state.myFilter = payload
    },
    setSortType(state,payload){
      console.log('setSortType INDEX',payload)
      state.sortType=payload
    },
    setColumns(state,payload){
      state.columns=payload
    },
    setThemeFilter(state, payload) {
      console.log("THEME FILTER CHANGED", payload)
      state.themeFilter = payload
    },
    setMovementFilter(state, payload) {
      state.movementFilter = payload
    },
    setShowShortRead(state, payload) {
      state.showShortRead = payload
    },
    setShowLongRead(state, payload) {
      state.showLongRead = payload
    },
    setShowDocumentary(state, payload) {
      state.showDocumentary = payload
    },
    setShowObject(state, payload) {
      state.showObject = payload
    },
    setShowPerson(state, payload) {
      state.showPerson = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setContent(state, payload) {
      state.content = payload
    },
    setDecades(state,payload){
      state.decades=payload
    },
    setMovements(state, payload) {
      state.movements = payload
    }, setLocations(state, payload) {
      state.locations = payload
    },
    setPeople(state, payload) {
      state.people = payload
    },
    setLocationItem(state, payload) {
      let found = state.locations.find(item => item.name === payload.name)
      if (!found) {
        if (!state.locations) { state.locations = [] }
        state.locations.push(payload)
      } else {
        Object.assign(found, payload)
      }
    },
    setMovementItem(state, payload) {
      let found = state.movements.find(item => item.name === payload.name)
      if (!found) {
        if (!state.movements) { state.movements = [] }
        state.movements.push(payload)
      } else {
        Object.assign(found, payload)
      }
    },
    setPeopleItem(state, payload) {
      let found = state.people.find(item => item.id === payload.id)
      if (!found) {
        if (!state.people) { state.people = [] }
        if (payload.dateOfBirth) { }
        state.people.push(payload)
      } else {
        Object.assign(found, payload)
      }
    },
    setSelectedContent(state, payload) {
      state.selectedContent = payload
    },
    setContentItem(state, payload) {
      if (payload.keyDates) {
        payload.keyDate = payload.keyDates[0]
      }
      let idx = payload.id.split(" ").join("_")
      let found = state.content.find(item => item.id === idx)
      if (!found) {
        if (!state.content) {
          state.content = []
        }
        state.content.push(payload)
      } else {
        Object.assign(found, payload)
      }
    }
  },
  actions: {
    getLocations({ commit }) {
      commit('setLoading', true)
      commit('setLocations', [])
      let cRef = firebase.firestore().collection("locations")
      cRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            let data = doc.data()
            commit('setLocationItem', data);
          })
          commit('setLoading', false)
        } else {
          console.log("QUERY NO DATA")
          commit('setLoading', false)
        }
      }).catch(err => {
        console.log('Error logged', err);
      })
    },
    getMovements({ commit }) {
      commit('setLoading', true)
      commit('setMovements', [])
      let cRef = firebase.firestore().collection("movements")
      cRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            let data = doc.data()
            commit('setMovementItem', data);
          })
          commit('setLoading', false)
        } else {
          console.log("QUERY NO DATA")
          commit('setLoading', false)
        }
      }).catch(err => {
        console.log('Error logged', err);
      })
    },
    getPeople({ dispatch, commit, getters }) {
      commit('setLoading', true)
      commit('setPeople', [])
      let peopleRef = firebase.firestore().collection("people")
      // peopleRef.orderBy("lastName").limit(7).get().then(querySnapshot => {
      // peopleRef.orderBy("lastName").get().then(querySnapshot => {
      peopleRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            let data = doc.data()
            commit('setPeopleItem', data);
          })
          commit('setLoading', false)
        } else {
          console.log("QUERY NO DATA")
          commit('setLoading', false)
        }
      }).catch(err => {
        console.log('Error logged', err);
      })
    },
    upDatePeople({ commit }) {},
    getContent({ dispatch, commit, getters }) {
    
      commit('setLoading', true)
      commit('setContent', [])
      firebase.firestore().collection("content").get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            let data = doc.data()
            data.id = doc.id.split(" ").join("_")
            commit('setContentItem', data);
            commit('setLoading', false)
          })
        } else {
          console.log("QUERY NO DATA")
          commit('setLoading', false)
        }
      }).catch(err => {
        console.log('Error logged', err);
      })
    },
    getDecades({ dispatch, commit, getters }) {
      console.log("DECADE LOOKING")
      commit('setLoading', true)
      commit('setDecades', [])
      
      firebase.firestore().collection("decades").get().then(querySnapshot => {
        if (!querySnapshot.empty) {
        
          let decades=[]
         
          querySnapshot.forEach(doc => {
            let data = doc.data()
            decades.push(data)
            //commit('setDecades', data)
            
          })
          commit('setDecades', decades)
          commit('setLoading', false)
        } else {
          console.log("QUERY NO DATA")
          commit('setLoading', false)
        }
      }).catch(err => {
        console.log('Error logged', err);
      })
    },
    removeDocument({ commit, getters }, payload) {
      alert("Remove Document " + payload.id + " " + payload.contType)
      firebase.firestore().collection(payload.contType).doc(payload.id).delete()
        .then(function () {
          console.log("Document successfully removed!");
          commit('setLoading', false)
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
          commit('setLoading', false)
        });
      // deleteDoc(doc(db, payload.contType, payload.id));
    },
    updateDocument({ commit, getters }, payload) {
      let collection = payload.type || "content"
      if (payload.type == "person") collection = "people"
      if (payload.type == "location") {
        collection = "locations"
      }
      if (collection == "content") {
        delete payload.content.displayName
      }
      console.log("UPDATE DOCUMENT", payload)
      console.log("UPDATE DOCUMENT DiSPLAY NAME", payload.content)
      console.log("UPDATE DOCUMENT DiSPLAY NAME", payload.content.characters)
      if ("DEATH DATE") {
        console.log("Death", payload.content.dateOfDeath)
      }
      if (payload.content.dateOfDeath == "Invalid Date") {
        console.log("NO Death", payload.content.dateOfDeath)
        // let mDate = new Date()
        payload.content.dateOfDeath = new Date(1000, 0, 1);
      }
      commit('setLoading', true)
      firebase.firestore().collection(collection).doc(payload.id).set(payload.content)
        .then(function () {
          console.log("Document successfully written!");
          commit('setLoading', false)
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
          commit('setLoading', false)
        });
    },
    addDocument({ dispatch, commit, getters }, payload) {
      let collection = "content"
      if (payload.type == "person") collection = "people"
      commit('setLoading', true)
      firebase.firestore().collection(collection).doc(payload.id).set(payload)
        .then(function () {
          if (payload.type == "person") {
            commit('setPeopleItem', payload)
          } else {
            commit('setContentItem', payload)
          }
          commit('setLoading', false)
          console.log("Document successfully written!");
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
          commit('setLoading', false)
        });
      // if (state.content[payload.id]) {
      //   Vue.set(state.content[payload.collection], payload.doc, payload.content)
      // } else {
      //   Vue.set(state.content, payload.id, [])
      //   Vue.set(state.text[payload.collection], payload.doc, payload.content)
      // }
    },
    resetFilters({ commit }) {
      commit("setTypeFilter", null)
    }
  },
  getters: {
    getEditContent(state) {
      return state.editContent
    },
    getCurrentArticles(state) {
      return state.currentArticles
    },
    getShowShare(state) {
      return state.showShare
    },
    getShowReorder(state) {
      return state.showReorder
    },
    getShowHolding(state) {
      return state.showHolding
    },
    getAllowSignUp(state) {
      return state.allowSignUp
    },
    getLetterSelected(state) {
      return state.letterSelected
    },
    getConvictLastNames(state) {
      return state.convictLastNames
    },
    getReshuffle(state) {
      return state.shuffle
    },
    getSearchQuery(state) {
      return state.searchQuery
    },
    getTypeFilter(state) {
      return state.typeFilter
    },
    getSortType(state){
      return state.sortType
    },
    getColumns(state){
      return state.columns
    },
    getThemeFilter(state) {
      return state.themeFilter
    },
    getMovementFilter(state) {
      return state.movementFilter
    },
    getMyFilter(state) {
      return state.myFilter
    },
    getShowShortRead(state) {
      return state.showShortRead
    },
    getShowLongRead(state) {
      return state.showLongRead
    },
    getShowDocumentary(state) {
      return state.showDocumentary
    },
    getShowObject(state) {
      return state.showObject
    },
    getShowPerson(state) {
      return state.showPerson
    },
    getPersonByID: (state) => (idx) => {
      return state.people.find(item => item.id === idx)
    },
    getContentByID: (state) => (idx) => {
      if (state.content) {
        if (idx) {
          idx = idx.split(" ").join("_")
          return state.content.find(item => item.id === idx)
        } else {
          console.log("NO ID")
        }
      } else {
        return { title: "Missing" + idx }
      }
    },
    getSelectedContent(state) {
      return state.selectedContent
    },
    getContentByIndex(state) {
      if (state.content) {
        return state.content[0]
      } else {
        return null
      }
    },
    getContent(state) {
      if (state.content && state.content.length > 0) {
        return state.content
      } else {
        return null
      }
    },
    getPeople(state) {
      return state.people
    },
    getLocations(state) {
      return state.locations
    },
    getMovements(state) {
      return state.movements
    },
    getDecades(state) {
      return state.decades
    },
    getMovementList(state) {
      return state.movementList
    },
    getZoom(state) {
      return state.zoom
    },
    getNavDisplay(state) {
      return state.navDisplay
    }
  }
}
<template>
  <div class="popup-container">
    <div class="popup">
      <MediaNavbar :content="content"></MediaNavbar>
      <div class="desktop-to-mobile">
        <div class="image-container">
          <img v-if="hasImage(content.images)" :src="imagePathPeople(content.images[0])">
          <img v-else :src="imagePath('empty.png')">
        </div>
        <div class="background"></div>
        <div class="text">
          <h1>{{ contentTitle(content) }}</h1>
          <p style="margin-bottom: 0.75em;">{{ parseDate(content.dateOfBirth) }} – {{ parseDate(content.dateOfDeath) }}</p>
          <p v-html="content.content"></p>
          <!-- Eventually replace <LabelContent> with <ExtraContent> (See Conviction Politics 2024 XD)-->
          <div style="margin-bottom: 1em;"></div>
          <div v-for="(c, ci) in content.items" :key="'c' + ci" @click="showContentL(c)">
            <LabelContent :item="getItem(c)"></LabelContent>
          </div>
          <!-- -->
        </div>
      </div>
      <ExtraContent :content="content"></ExtraContent>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { CSSPlugin } from 'gsap/CSSPlugin'
gsap.registerPlugin()
gsap.registerPlugin(CSSPlugin)

import MediaNavbar from '@/components/MediaNavbar.vue'
import LabelContent from '@/components/LabelContent.vue'
import contentMixin from '@/mixins/content.js'
export default {
  name: 'MediaPerson',
  components: { MediaNavbar, LabelContent },
  mixins: [contentMixin],
  props: {
    content: { default: null }
  },
  data() {
    return {
      popOver: false,
      popLeft: 0,
      popTop: 0,
      selectedTile: null,
    };
  },
  computed: {
    firebasePath() {
      return this.$store.getters.getFirebasePath
    },
    pDetails() {
      if (this.content && this.content.details) {
        return this.content.details
      } else {
        return null
      }
    }
  },
  methods: {
    pDetailsSplit(detail, index) {
      let colon = ":"
      if (index == 1) {
        colon = ""
      }
      return detail.split(":")[index] + colon
    },
    showContentL(cont) {
      this.showContent(this.getItem(cont))
      this.selectedTile = null
    },
    getItemByID(id) {
      return this.$store.getters.getContentByID(id)
    },
    getItem(contID) {
      return this.getItemByID(contID)
    },
    hasImage(images) {
      if (images && images[0]) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-container {
  width: 920px;
  margin: auto;
  margin-top: 6em;
  position: static;
  transition: 0.25s;
}
.popup {
  outline: var(--white) 2px solid;
  padding: 2px;
  margin-bottom: 6em;
  background-color: var(--black);
  filter: drop-shadow(0 0 2em var(--half-black));
  overflow: hidden;
}
.text {
  text-align: left;
  padding: 1.5em 1.25em;
  background-color: var(--grey);
  outline: 2px solid var(--black);
  overflow-y: scroll;
  flex-grow: 1;
  h1 {
    margin-bottom: 0.25em;
  }
}
audio {
  width: 100%;
  height: 2.5em;
  margin-bottom: 0.75em;
  filter: invert(1);
}
.desktop-to-mobile {
  display: flex;
  flex-direction: row;
  gap: 2px;
}
.image-container {
  display: flex;
  align-items: center;
  position: relative;
  max-height: 24em;
}
img {
  width: 24em;
  height: 24em;
  max-height: 24em;
  object-fit: cover;
}
.background {
  position: absolute;
  top: calc(26.8em);
  height: 100%;
  width: 100%;
  background: url("../assets/menu.png") top center no-repeat;
  background-size: calc(100% - 6px);
  z-index: -1;
}
@media only screen and (max-width: 1000px) {
  .desktop-to-mobile {
    flex-direction: column;
  }
  img {
    width: 100%;
    height: 16em;
    min-height: 16em;
  }
  .background {
    display: none;
  }
}
@media only screen and (max-width: 660px) {
  .popup-container {
    width: 100vw;
    margin-top: 0;
  }
  .popup {
    outline: none;
    padding: 0;
    filter: none;
  }
  .text {
    padding: 1.25em 1em;
  }
}
</style>
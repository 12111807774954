<template>
  <div v-if="content">
    <div style="display: flex; margin-bottom: 2px;" :class="titleBG">
      <div class="content-navbar">
        <div :class="'category-icon icon-' + iconType(content.type)"></div>
        <h2 class="category-text">{{ itemType(content.type,content.song) }}</h2>
      </div>
      <UserIcons :showShare="true" :showClose="true" data:="userData" :item="content"></UserIcons>
    </div>
  </div>
</template>

<script>
import contentMixin from '@/mixins/content.js'
import UserIcons from '@/components/UserIcons.vue'
export default {
  name: 'MediaNavbar',
  components: { UserIcons },
  mixins: [contentMixin],
  props: {
    content: { default: null }
  }
}
</script>

<style lang="scss" scoped>
.content-navbar {
  display: flex;
  flex-grow: 1;
  background: linear-gradient(to right, var(--black) 0%, transparent 100%);
}
.category-text {
  padding: 0.5em 0 0.2em;
}
.category-icon {
  font-size: 1.5em;
  padding: 0.25em 0.25em 0;
}
</style>
import Vue from 'vue'
import { Store } from 'vuex';
export default {
  state: {
    entryPath: "NO PATH",
    editorPath: null,
    sharedPath: null,
    webPath: null,
    dataType: "People",
    pagePath: null,
    firebasePath: "https://firebasestorage.googleapis.com/v0/b/convictionpolitics-f7.appspot.com/o/",
    messageShowing: true
  },
  mutations: {
    setWebPath(state,payload){
      state.webPath=payload
    },
    setMessageShowing(state,payload){
      state.messageShowing=payload
    },
    setPagePath(state,payload){
      state.pagePath=payload
    },
    setEntryPath(state,payload){
      state.entryPath=payload
    },
    setEditorPath(state,payload){
      console.log("SET EDITOR PATH",payload)
      state.editorPath=payload
    },
    setSharedPath(state,payload){
      state.sharedPath=payload
    },
    setDataType(state,payload){
      state.dataType=payload
    }
  },
  actions: {
  },
  getters: {
    getWebPath(state){
      return state.webPath
    },
    getMessageShowing(state){
      return state.messageShowing
    },
    getFirebasePath(state){
      return state.firebasePath
    },
    getEntryPath(state){
      console.log("GETTING ENTRY",state.entryPath)
      return state.entryPath
    },
    getSharedPath(state){
      return state.sharedPath
    },
    getEditorPath(state){
      console.log("GETTING EDITOR",state.editorPath)
      return state.editorPath
    },
    getDataType(state){
      return state.dataType
    },
    getPagePath(state){
      return state.pagePath
    }
  }
}
<template>
<div class="popup-container">
    <div class="popup">
      <div style="display: flex; margin-bottom: 1.0em; background-color: var(--brown);">
        <div class="content-navbar">
          <div class="category-icon icon-pinned"></div>
          <h2  class="category-text">REORDER PINS</h2>
        </div>
        <div class="icon-box">
          <div @click="closePopup" class="icon-close icon"></div>
        </div>
      </div>
      <h2 style="margin-bottom: 0.25em;"> Drag and Drop the pins to reorder them</h2>
   
    <Div id="pinlist">
      <div v-for="(l,li) in  pinList" :key="('l'+li)" class="list-item" :id="'li'+li">
        <div class="item-content">
          <span class="order">{{ (l.index+1) }} </span>  {{l.text}} 
        </div>
      </div>
      
    </div>
      
   
      
  </div>
  </div>

</template>
<script>


import { gsap } from "gsap";
import { Draggable } from 'gsap/Draggable'
gsap.registerPlugin(Draggable)
export default {
 components: {
   
  },
  data() {
    return {
      pinList:[],
      sortable:null,
    }
  },
  mounted(){
    console.log("REORDER MOUNTED")
     this.pinList=this.pins.sort(indexSort)
     setTimeout(this.setUpreorder,500)
    
  },
  computed:{
   
      userIsLoaded(){
        return this.$store.getters.getUserIsLoaded
      },
      userData(){
           return this.$store.getters.getUserData
    },
    pins(){
      return this.userData.data.pinned
    }
    
  },
  
 
  
  // METHODS
  methods: {
    saveItemReordered () {
        this.userData.pinned=this.pinList
        this.$store.dispatch("updateUserData",{type:"pinned",data:this.userData.data})
    },
    closePopup(){
      this.$store.commit('setShowReorder',false)
    },
    doDragEnd(){
      console.log("DRAG END",this.sortable)
      let id
      for(var e in this.sortable.children){
        id=this.sortable.children[e].id
        if(id){
          id=parseInt(id.split('li')[1],10)
          this.pinList[id].index=parseInt(e,10)
        }
      }
      this.saveItemReordered()
    },
    setUpreorder(){
      var self=this
      createSortable("#pinlist");
     

function createSortable(selector) {
  var sortable = document.querySelector(selector);
  self.sortable=sortable
  Draggable.create(sortable.children, {
    type: "y",
    bounds: sortable,
    edgeResistance: 1,
    onPress: sortablePress,
    onDragStart: sortableDragStart,
    onDrag: sortableDrag,
    liveSnap: sortableSnap,
    onDragEnd: sortableDragEnd
  });
}

function sortablePress() {
  var t = this.target,
      i = 0,
      child = t;
      while(child = child.previousSibling)
        if (child.nodeType === 1) i++;
      t.currentIndex = i;
      t.currentHeight = t.offsetHeight;
      t.kids = [].slice.call(t.parentNode.children); // convert to array
  }

function sortableDragStart() {
  //gsap.set(this.target, { color: "#88CE02" });
}
                 
function sortableDrag() {
  var dur =0.2
  var t = this.target,
      elements = t.kids.slice(), // clone
      indexChange = Math.round(this.y / t.currentHeight),
      bound1 = t.currentIndex,
      bound2 = bound1 + indexChange;
  if (bound1 < bound2) { // moved down
    gsap.to(elements.splice(bound1+1, bound2-bound1),  {duration:dur, yPercent: -100 });
    gsap.to(elements,  {duration:dur, yPercent: 0 });
  } else if (bound1 === bound2) {
    elements.splice(bound1, 1);
    gsap.to(elements,  {duration:dur, yPercent: 0 });
  } else { // moved up
    gsap.to(elements.splice(bound2, bound1-bound2),  {duration:dur, yPercent: 100 });
    gsap.to(elements,  {duration:dur, yPercent: 0 });
    console.log("---------------")
    

    }
    
    
  
}

function sortableSnap(y) {
  var h = this.target.currentHeight;
  return Math.round(y / h) * h;
}
                 
function sortableDragEnd() {
  var t = this.target,
      max = t.kids.length - 1,
      newIndex = Math.round(this.y / t.currentHeight);
      newIndex += (newIndex < 0 ? -1 : 0) + t.currentIndex;
      if (newIndex === max) {
        t.parentNode.appendChild(t);
      } else {
        t.parentNode.insertBefore(t, t.kids[newIndex+1]);
      }
      gsap.set(t.kids, { yPercent: 0, overwrite: "all" });
      gsap.set(t, { y: 0, color: "" });
      
      setTimeout(self.doDragEnd,200)
    }
 }
    
  },
  watch:{
   pins(pins){
     this.pinList=[]
     for (p in pins){
       this.pinList.pushpins[p]
     }
   }
  }
  
}
function  indexSort(a, b) {
      if (a.index < b.index) {
        return -1;
      }
      if (a.index > b.index) {
        return 1;
      }
      return 0;
    }

</script>

<style lang="scss" scoped>

.popup-container {
  width: 600px;
  margin: auto;
  position: static;
  transition: 0.25s;
}
.popup {
  outline: var(--white) 2px solid;
  padding: 2px;
  background-color: var(--black);
  filter: drop-shadow(0 0 2em var(--half-black));
  overflow: hidden;
}
.content-navbar {
  display: flex;
  flex-grow: 1;
  background: linear-gradient(to right, var(--black) 0%, transparent 100%);
}
.category-text {
  padding: 0.5em 0 0.2em;
}
.category-icon {
  font-size: 1.5em;
  padding: 0.25em 0.25em 0;
}
.logo {
  width: 3.5em;
  margin: -0.75em 1em 0 0;
}
.icon-box {
  display: flex;
  height: 100%;
}
.icon {
  width: 2em;
  font-size: 1.5em;
  line-height: 1.6em;
  color: var(--black);
  background-color: transparent;
  transition: 0.25s;
}
.icon:hover, .active {
  color: var(--black);
  background-color: var(--white);
}
.shared-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em;
  h1 {
    text-align: left;
    max-width: 400px;
  }
}
.shared-link {
  display: flex;
  flex-direction: column;
  margin: 2em;
  justify-content: center;
}
button {
  width: 12em;
  color: var(--black);
  background-color: var(--brown);
  padding: 1em 2em 0.5em;
  border: none;
  transition: 0.1s;
  display: flex;
  justify-content: center;
  margin-top: 1em;
  .icon-link, .icon-share {
    font-size: 1.5em;
    margin-top: -0.25em;
    margin-left: -0.25em;
    margin-right: 0.25em;
  }
}
button:hover {
  background-color: var(--white);
}
.list-item{
  color: var(--black);
  background-color: var(--brown);
  padding: 1em 2em 0.5em;
  border: #000 0.5em solid;
  transition: 0.1s;
  touch-action: pan-x;
    font-family: "DDin", Roboto, sans-serif;
    font-size: 1em;
    line-height: 1.3em;
    margin: 0;
  

}
.list-item:hover{
  color: var(--black);
  background-color: var(--white);
}
</style>

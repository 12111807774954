<template>
  <div class="popup-container">
    <div class="popup">
      <div style="display: flex; margin-bottom: 2.5em; background-color: var(--brown);">
        <div class="content-navbar">
          <div class="category-icon icon-share"></div>
          <h2  v-if="signUp" class="category-text">CREATE ACCOUNT</h2>
          <h2 v-else class="category-text">SIGN IN</h2>
        </div>
        <div class="icon-box">
          <div @click="closePopup" class="icon-close icon"></div>
        </div>
      </div>

      <div v-if="userIsLoaded">
        <h2 style="margin-bottom: 0.25em;">Current User</h2>
        <h1>{{ userData.name }}</h1>
        <div v-if="isTester && debug">{{ userData }}</div>
        <form>
          <div class="form-group" style="display: flex; justify-content: center;">
            <button :class="{ active: false }" @click.prevent="dashboard()"><h2>GO TO INDEX</h2></button>
            <button :class="{ active: false }" @click.prevent="signOut()"><h2>SIGN OUT</h2></button>
          </div>
        </form>
      </div>

      <div v-else>
        <form>
          <div class="form-group" v-if="signUp">
            <input type="text" v-model="displayName" />
            <label class="form-label" for="displayName"><p>FULL NAME</p></label>
          </div>
          <div class="form-group">
            <input type="text" v-model="email" />
            <label class="form-label" for="email">
              <p>EMAIL</p>
              <p class="message" v-if="email.length > 1">{{ email_msg }}</p>
            </label>
          </div>
          <div class="form-group">
            <input type="password" v-model="password1" @keyup="checkPassword1" />
            <label class="form-label" for="psw1">
              <p>PASSWORD</p>
              <p class="message" v-show="msg1">{{ pwd1_msg }}</p>
            </label>
            <p style="text-align: left; text-decoration: underline;" v-if="email.length > 1 && email_msg == '' && !signUp" @click.prevent="forgot()">Forgotten Your Password?</p>
          </div>
          <div class="form-group" v-if="signUp">
            <input type="password" v-model="password2" @keyup="checkPassword2" />
            <label class="form-label" for="psw2">
              <p>REPEAT PASSWORD</p>
              <p class="message" v-show="msg2">{{ pwd2_msg }}</p>
            </label>
          </div>
            <h2 style="margin: 1em 0 0.5em;">{{ LoginMessage }}</h2>
            <h2 style="margin: 1em 0 0.5em;">{{ LoginError }}</h2>
          <div class="form-group">
            <button v-if="signUp" @click="signUp = !signUp"><h2>BACK TO SIGN IN</h2></button>
            <button v-else @click.prevent="signUpClick"><h2>CREATE ACCOUNT</h2></button>
            <div style="flex-grow: 1;"></div>
            <button v-if="signUp" :class="{ active: disable_btn }" :disabled="disable_btn" @click.prevent="on_signup()"><h2>CREATE ACCOUNT</h2></button>
            <button v-else :class="{ active: false }" @click.prevent="on_signin()"><h2>SIGN IN</h2></button>
          </div>
        </form>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      signUp: false,
      displayName: '',
      email: '',
      email_msg: '',
      name_msg: '',
      password1: '',
      pwd1_msg: '',
      password2: '',
      pwd2_msg: '',
      disable_btn: true,
      msg1: true,
      msg2: true,
    }
  },
  computed: {
    allowSignUp() {
      return this.$store.getters.getAllowSignUp
    },
    isTester() {
      return this.$store.getters.getIsTester
    },
    debug() {
      return this.$store.getters.getDebug
    },
    userIsLoaded() {
      return this.$store.getters.getUserIsLoaded
    },
    userData() {
      return this.$store.getters.getUserData
    },
    LoginError(){
      return this.$store.getters.getLoginError
    },
    LoginMessage() {
      return this.$store.getters.getLoginMessage
    }
  },
  methods: {
    signUpClick() {
      console.log("SIGN UP CLICK", this.AllowSignUp)
      if (this.AllowSignUp || true) {
        this.signUp = !this.signUp
      } else {
        // this.$store.commit("setShowHolding",true)
      }
    },
    checkPassword1: function () {
      console.log("PW CHANGE X", this.password1)
      if (this.check_password_length(this.password1, 'pwd1_msg', 6)) {
        this.check_passwords_match();
      }
    },
    checkPassword2: function () {
      console.log("PW2 CHANGE", this.password2)
      if (this.check_password_length(this.password2, 'pwd2_msg', 6)) {
        this.check_passwords_match();
      }
    },
    forgot() {
      console.log("FORGOT")
      this.$store.dispatch('forgotPassword', this.email)
      alert("An email has been sent to " + this.email + ". Click on the link in the email to reset your password.")
    },
    closePopup() {
      this.$store.commit('setShowSignIn', false)
    },
    valid_email(email, msg) {
      console.log("Valid email")
      let valid = true
      if (email.split("@").length != 2) {
        valid = false
      }
      if (email.lastIndexOf(".") < email.lastIndexOf("@")) {
        valid = false
      }
      if (valid) {
        this[msg] = '';
        return true;
      } else {
        this[msg] = 'Keep typing... waiting for valid email.';
        return false;
      }
    },
    valid_name(name, msg) {
      if (name && name.length > 1) {
        this[msg] = '';
        return true;
      } else {
        return false;
      }
    },
    check_password_length(value, msg, total) {
      var length = value.length;
      var sum = 0;
      var display;
      sum = (total - length);
      switch (sum) {
        case 0:
          display = '';
          break;
        case 1:
          display = 'Password is too short, 6+ characters required.';
          break;
        default:
          display = 'Password is too short, 6+ characters required.';
      }
      if (length >= total) {
        this[msg] = '';
        return true;
      } else {
        this[msg] = display;
        return false;
      }
    },
    check_passwords_match() {
      this.$nextTick(() => {
        if (this.password1.length > 5 && this.password2.length > 5) {
          if (this.password2 != this.password1) {
            console.log(this.password2, this.password1)
            this.pwd2_msg = 'Passwords do not match';
            this.disable_btn = true;
            return true;
          } else {
            this.pwd2_msg = '';
            if (this.displayName.length > 1) {
              this.disable_btn = false;
            }
            return false;
          }
        }
      })
    },
    on_signup() {
      this.$store.dispatch('signUserUp', { email: this.email, password: this.password1, displayName: this.displayName })
      // this.displayName = ''
      // this.email = ''
      // this.password1 = ''
      // this.password2 = ''
      // this.email_msg = ''
      // this.pwd1_msg = ''
      // this.pwd2_msg = ''
      // this.msg1 = false
      // this.msg2 = false
      // this.disable_btn = true
      // this.$emit('change_comp', 'results');
    },
    on_signin() {
      console.log("SIGN IN")
      this.$store.dispatch('signUserIn', { email: this.email, password: this.password1 })
      this.$store.commit("setLoginMessage", "Signing In")
      // setTimeout(this.clearForm,5000)
      // this.$emit('change_comp', 'results');
    },
    clearForm() {
      this.LoginMessage = ''
      this.displayName = ''
      this.email = ''
      this.password1 = ''
      this.password2 = ''
      this.email_msg = ''
      this.pwd1_msg = ''
      this.pwd2_msg = ''
      this.msg1 = false
      this.msg2 = false
      this.disable_btn = true
    },
    signOut() {
      this.$router.push({ path: '/' })
      this.$store.dispatch("signUserOut")
    },
    show_terms() {
      // this.$emit('change_comp', 'terms');
    },
    dashboard() {
      this.$router.push({ path: "index" })
      // this.$router.push({path:"upload?editor=dashboard"})
      this.closePopup()
    }
  },
  watch: {
    email: function (value) {
      this.valid_email(value, 'email_msg');
    },
    displayName: function (value) {
      console.log("Name Change")
      this.valid_name(value, 'name_msg');
      this.check_passwords_match()
    },
    userIsLoaded(val) {
      if (val) {
        this.$router.push({ path: "index" })
        // this.$router.push({path:"upload?editor=dashboard"})
        this.closePopup()
      }
    },
    signUp() {
      this.clearForm
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-container {
  width: 600px;
  margin: auto;
  position: static;
  transition: 0.25s;
}
.popup {
  outline: var(--white) 2px solid;
  padding: 2px;
  background-color: var(--black);
  filter: drop-shadow(0 0 2em var(--half-black));
  overflow: hidden;
}
.content-navbar {
  display: flex;
  flex-grow: 1;
  background: linear-gradient(to right, var(--black) 0%, transparent 100%);
}
.category-text {
  padding: 0.5em 0 0.2em;
}
.category-icon {
  font-size: 1.5em;
  padding: 0.25em 0.25em 0;
}
.logo {
  width: 3.5em;
  margin: -0.75em 1em 0 0;
}
.icon-box {
  display: flex;
  height: 100%;
}
.icon {
  width: 2em;
  font-size: 1.5em;
  line-height: 1.6em;
  color: var(--black);
  background-color: transparent;
  transition: 0.25s;
}
.icon:hover, .active {
  color: var(--black);
  background-color: var(--white);
}
.shared-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em;
  h1 {
    text-align: left;
    max-width: 400px;
  }
}
.shared-link {
  display: flex;
  flex-direction: column;
  margin: 2em;
  justify-content: center;
}
button {
  width: 12em;
  color: var(--black);
  background-color: var(--brown);
  padding: 1em 2em 0.5em;
  border: none;
  transition: 0.1s;
  display: flex;
  justify-content: center;
  margin-top: 1em;
  .icon-link, .icon-share {
    font-size: 1.5em;
    margin-top: -0.25em;
    margin-left: -0.25em;
    margin-right: 0.25em;
  }
}
button:hover {
  background-color: var(--white);
}
input {
  font-family: "DDin", Roboto, sans-serif;
  font-size: 1em;
  line-height: 1.3em;
  margin: 0;
  border-radius: 0.25em;
  outline: 1px var(--brown) solid !important;
  color: var(--brown) !important;
  background-color: var(--grey) !important;
  width: 100% !important;
  height: 2em !important;
  text-align: center !important;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2em;
  margin-top: 1em; 
}
.form-group {
  margin: 0.25em 0;
  label {
    display: flex;
    justify-content: space-between;
    margin: 0.5em 0;
  }
  input {
    border: none;
    outline: none;
  }
  .message {
    color: var(--brown)
  }
}
.form-group:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  button.active {
    opacity: 0.5;
    cursor: default;
  }
}
</style>
<template>
  <div v-if="content">
    <!-- <h2 :class="titleCol">Associated Content</h2> -->
    <div class="associated-content">
      <div v-for="(cl, cil) in  assContent(content).links" :key="'cl' + cil" :style="'flex-grow: 1;' + also(cil)">
        <AssociatedCont v-if="cil < assItems(content).links" :content="cl" s reason="ALSO"></AssociatedCont>
      </div>
      <div v-for="(cn, cin) in  assContent(content).nearby" :key="'cn' + cin" :style="'flex-grow: 1;' + nearby(cin)">
        <AssociatedCont v-if="cin < assItems(content).nearby" :content="cn" reason="NEARBY"></AssociatedCont>
      </div>
      <div v-for="(c, ci) in  assContent(content).meanwhile" :key="'c' + ci" :style="'flex-grow: 1;' + meanwhile(ci)">
        <AssociatedCont v-if="ci < assItems(content).meanwhile" :content="c" reason="MEANWHILE"></AssociatedCont>
      </div>
      <div v-for="(cb, cib) in  assItems(content).blank" :key="'cb' + cib" :style="'flex-grow: 1;' + blank(cib)">
        <AssociatedCont :content="null" reason="MEANWHILE"></AssociatedCont>
      </div>
    </div>
  </div>
</template>

<script>
import AssociatedCont from '@/components/AssociatedCont.vue'
import contentMixin from '@/mixins/content.js'
export default {
  name: 'ExtraContent',
  components: { AssociatedCont },
  mixins: [contentMixin],
  props: {
    content: { default: null }
  },
  computed: {
    firebasePath() {
      return this.$store.getters.getFirebasePath
    },
  },
  methods: {
    nearby(cin) {
      if (cin < this.assItems(this.content).nearby) {
        return ""
      }
      return " display: none;"
    },
    also(cin) {
      if (cin < this.assItems(this.content).links) {
        return ""
      }
      return " display: none;"
    },
    meanwhile(cin) {
      if (cin < this.assItems(this.content).meanwhile) {
        return ""
      }
      return " display: none;"
    },
    blank(cin) {
      if (cin < this.assItems(this.content).blank) {
        return ""
      }
      return ""
    },
    assItems(content) {
      let maxItems = 3
      let items = this.assContent(content)
      let links = items.links.length
      let meanwhile = items.meanwhile.length
      let nearby = items.nearby.length
      let blank = 0
      links = Math.min(links, maxItems - (Math.min(meanwhile, 1)) - Math.min(nearby, 1))
      let remaining = maxItems - links
      let remainingMax = remaining - nearby
      let remainingHalf = Math.max(remainingMax, Math.floor(remaining / 2))
      meanwhile = Math.min(remainingHalf, meanwhile)
      nearby = Math.min(nearby, maxItems - links - meanwhile)
      console.log("BLANK", { links: links, meanwhile: meanwhile, nearby: nearby, blank: blank })
      blank = maxItems - links - meanwhile - nearby
      return { links: links, meanwhile: meanwhile, nearby: nearby, blank: blank }
    },
    getItemByID(id) {
      return this.$store.getters.getContentByID(id)
    },
    getItem(contID) {
      return this.getItemByID(contID)
    },
    hidePop() {
      this.$store.commit("setShowShortRead", null)
    },
    hasImage(images) {
      if (images && images[0]) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.associated-content {
  display: flex;
  gap: 2px;
}
@media only screen and (max-width: 660px) {
  .associated-content {
    flex-direction: column;
    gap: 0;
  }
}
</style>
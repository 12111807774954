<template>
  <div v-if="item">
    <div class="icon-box" v-if="userData">
      <div v-if="!hideViewed" @click="setViewed(item)" :class="'icon-viewed icon' + viewed(item)"></div>
      <div                    @click="setLiked(item)"  :class="'icon-favourite icon' + liked(item)"></div>
      <div v-if="showPinning" @click="setPinned(item)" :class="'icon-pinned icon' + pinned(item)"></div>
      <div v-if="showShare"   @click="doShare(item)"   :class="'icon-share icon'"></div>
      <div v-if="showClose"   @click="doClose"         :class="'icon-close icon close'"></div>
    </div>
    <div class="icon-box" v-else>
      <!-- Show Hidden Icons without Signing In -->
      
      <!-- <div :class="'icon-viewed icon'"></div>
      <div :class="'icon-favourite icon'"></div>
      <div :class="'icon-pinned icon'"></div> -->
      
      <div v-if="showShare" @click="doShare(item)" class="icon-share icon"></div>
      <div v-if="showClose" @click="doClose"       class="icon-close icon close"></div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: 'UserIcons',
  props: {
    hideViewed: true,
    showShare: false,
    showClose: false,
    item: {
      userData: Object,
      type: Object,
      default: () => ({
        label: null,
        type: "TYPE",
        theme: "Unrest"
      })
    }
  },
  computed: {
    userData() {
      if (this.$store.getters.getUserData) {
        return this.$store.getters.getUserData.data
      }
      return null
    },
    viewedItems() {
      return this.userData.viewed
    },
    pinnedItems() {
      return this.userData.pinned
    },
    likedItems() {
      return this.userData.liked
    },
    showPinning(){
      if(this.$route.name=="people"){
        return false
      }else{
        return true
      } 
    }
  },
  methods: {
  
    doClose() {
      this.$parent.hidePop()
    },
    doShare(item) {
      let id = this.idCode(item.id)
      console.log("Share the item", id)
      this.$store.commit("setCurrentArticles", item.id)
      this.$store.commit("setShowShare", item.id)
    },
    idCode(id) {
      if (id) {
        let idA = id.split("_")
        return idA[idA.length - 2] + "_" + idA[idA.length - 1]
      }
      return null
    },
    viewed(item) {
      if (this.viewedItems) {
        if (this.viewedItems.includes(this.idCode(item.id))) {
          return " active"
        }
      }
      return ""
    },
    pinned(item) {
      let isPinned = false
      if (this.pinnedItems) {
        for (var p in this.userData.pinned) {
          if ((this.userData.pinned[p].id == this.idCode(item.id) || item.pinned) && !item.unpinned) {
            isPinned = true
          }
        }
        if (isPinned) {
          return " active"
        }
      }
      return ""
    },
    liked(item) {
      if (this.likedItems) {
        if ((this.userData.liked.includes(this.idCode(item.id)) || item.liked) && !item.unliked) {
          return " active"
        }
      }
      return ""
    },
    setLiked(item) {
      let id = this.idCode(item.id)
      if (this.userData.liked) {
        if (this.userData.liked.includes(id)) {
          this.userData.liked.splice(this.userData.liked.indexOf(id), 1)
          item.liked = false
          item.unliked = true
        } else {
          this.userData.liked.push(id)
          item.liked = true
          item.unliked = false
        }
      } else {
        this.userData.liked = [id]
      }
      this.$store.dispatch("updateUserData", { type: "liked", data: this.userData })
    },
    setPinned(item) {
      let id = this.idCode(item.id)
      console.log("Pin the item", id)
      let foundPinned = false
      let foundIndex = 0
      if (this.userData.pinned) {
        for (var p in this.userData.pinned) {
          if (this.userData.pinned[p].id == id) {
            foundPinned = true
            foundIndex = p
          }
        }
        if (foundPinned) {
          console.log("REMOVE THIS")
          this.userData.pinned.splice(foundIndex, 1)
          item.pinned = false
          item.unpinned = true
        } else {
          this.userData.pinned.push({ id: id, text: item.title, index: this.userData.pinned.length + 1 })
          console.log("ADD THIS", this.userData.pinned)
          item.unpinned = false
          item.pinned = true
        }
        for (var p in this.userData.pinned) {
          this.userData.pinned[p].index = p
        }
        // if (this.userData.pinned.includes(id)) {
        //   this.userData.pinned.splice(this.userData.pinned.indexOf(id), 1)
        // } else {
        //   this.userData.pinned.push(id)
        // }
      } else {
        this.userData.pinned = [{ id: id, text: item.title, index: 1 }]
        console.log("NEW ITEM THIS")
      }
      this.$store.dispatch("updateUserData", { type: "pinned", data: this.userData })
      console.log(this.pinnedItems)
    },
    setViewed(item) {
      
      let id = this.idCode(item.id)
      console.log("View the item", id)
      if (this.userData.viewed) {
        if (this.userData.viewed.includes(id)) {
          console.log("REMOVE IT",id)
          this.userData.viewed.splice(this.userData.viewed.indexOf(id), 1)
          item.viewed=false
        } else {
          this.userData.viewed.push(id)
        }
      } else {
        this.userData.viewed = [id]
      }
      this.$store.dispatch("updateUserData", { type: "viewed", data: this.userData })
      console.log(this.viewedItems)
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-box {
  display: flex;
  height: 100%;
}
.icon {
  width: 2em;
  font-size: 1.5em;
  line-height: 1.6em;
  color: var(--white);
  background-color: var(--black);
  transition: 0.25s;
}
.icon:hover, .active {
  color: var(--black);
  background-color: var(--white);
}
.active:hover {
  background-color: var(--grey-light);
}
.close {
  color: var(--black);
  background-color: transparent;
}
</style>
import firebase from 'firebase/compat/app';
//import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
require('firebase/auth')
import { store } from '..'
import gsap from "gsap";

export default {
  state: {
    user: null,
    admin: false,
    curator: false,
    userData: null,
    userDataID:null,
    entryUrl:null,
    userLoading:false,
    userIsLoaded:false,
    loginError:"",
    showSignIn:false,
    loginNag:true,
    debug:false,
    loginMessage:"",
    fireBaseLoc:"https://storage.googleapis.com/convictionpolitics-f7.appspot.com/"
    

  },
  mutations: {
    setloginError(state,payload){
      
      if(payload && payload.indexOf("already in use")){
        state.loginError= "The email address is already in use by another account."
      }else{
      state.loginError=payload
      }
    },
    setShowSignIn(state,payload){
      state.showSignIn=payload
    },
    setLoading(state,payload){
        state.userLoading=payload
    },
    setUserIsLoaded(state,payload){
      state.userIsLoaded=payload
    },
    setEntryUrl(state,payload){
      state.entryUrl=payload
    },
    setUserData(state, payload) {
      
      state.userData = payload
    },
    setAdmin(state, payload) {
      state.admin = payload
    },
    setCurator(state, payload) {
      state.curator = payload
    },

    assignContentToUser(state, payload) {
      const id = payload.id
      state.user.fbKeys[id] = payload.fbKey
    },

    setUser(state, payload) {
      state.user = payload
    },
    setUserDataID(state,payload){
      state.userDataID = payload
    },
    setLoginMessage(state,payload){
      state.loginMessage=payload
    }

  },
  actions: {

   
    
   
    signUserUp({ dispatch,commit }, payload) {

      commit('setLoading', true)
      //commit('clearError')
      firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then(user => {
         
          commit('setLoading', false)
          commit('setloginError', false)
          const newUser = {
            //id: user.uid,
            id: user.user.uid,
            admin: false,
            user: true,
            name:payload.displayName,
            plays:0,
            data:{liked:[],pinned:[],viewed:[]}
          }
          var cuser = firebase.auth().currentUser;
          cuser.updateProfile({
            displayName: payload.displayName
             }).then(function() {
            console.log("NAME  Update successful.")
            }, function(error) {
           console.log("NAME  Update broken.",error)
             }); 
          firebase.firestore().collection("users").add(newUser)
            .then(function(docRef) {
              dispatch('sendVerificationEmail')
              console.log("Document written with ID: ", docRef.id);
             
            })
            .catch(function(error) {
              console.error("Error adding document: ", error);
            });
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setloginError', error.toString())
         
        })
    },

    signUserIn({ dispatch,commit }, payload) {
        firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(data => {
            console.log("SIGN IN2",data.user)
         // commit('setLoading', false)
          const newUser = {
            id: data.user.uid,
            displayName: data.user.displayName,
            email:data.user.email
          }
          commit('setUser', newUser)
          commit('setLoginMessage', "")
          dispatch('fetchUserData')
        })
        .catch(error => {
        //  commit('setLoading', false)
        //  commit('setError', error)
          console.log(error)
          commit('setLoginMessage', "Invalid Details - Check Your Email and Password")
        })
    },
    signUserOut({ commit }){
      firebase.auth().signOut().then(function() {
        // Sign-out successful.
        commit('setUser', null)
        
        location.reload();
      }, function(error) {
        // An error happened.
      });
      
    },
    sendVerificationEmail(){
      var user = firebase.auth().currentUser;
          user.sendEmailVerification().then(function() {
            console.log("EMAIL SENT")
          }).catch(function(error) {
            // An error happened.
          });
    },
    forgotPassword({},emailAddress){
      var auth = firebase.auth();
      console.log("forgot",emailAddress)
      auth.sendPasswordResetEmail(emailAddress).then(function() {
          // Email sent.
          console.log("EMAIL SENT")
        }).catch(function(error) {
          // An error happened.
        });
    },
    autoSignIn({ dispatch, commit, getters }, payload) {
  
     
      commit('setUser', { id: payload.uid, displayName:payload.displayName,email:payload.email })
      dispatch('fetchUserData')
    },
   
   
    fetchUserData({ dispatch,commit, getters }) {
    console.log("FETCH DATA")
     if(!getters.user){
      console.log("FETCH DATA No USER")
       return
     }
      var content = firebase.firestore().collection("users").where("id", "==", getters.user.id)
      content.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
         
          commit('setUserData', doc.data())
          commit('setUserDataID',doc.id)
         
        });
        commit("setUserIsLoaded",true)
        commit('setReshuffle',true)
        commit('setLoading', false)
      });
    },
    fetchStatus({ dispatch,commit, getters }) {
    
     
      var content = firebase.firestore().collection("website")
      content.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
         console.log("WEBSITE",doc.data())
         // commit('setUserData', doc.data())
          commit('setShowHolding',doc.data().showHolding)
          commit('setAllowSignUp',doc.data().allowSignUp)
         
        });
        //commit("setUserIsLoaded",true)
        
        //commit('setLoading', false)
      });
    },

   
    logout({ commit }) {
      firebase.auth().signOut()
      commit('logout')
    },
    updateUserData({commit,getters },payload){
      console.log("UPDATE",payload.type,payload.data)
      
       commit('setLoading',true)
       if(payload.type=="liked"){
       firebase.firestore().collection("users").doc(getters.getUserDataID).update({data:payload.data})
       }else if(payload.type=="viewed"){
        firebase.firestore().collection("users").doc(getters.getUserDataID).update({data:payload.data})
        }else if(payload.type=="pinned"){
          firebase.firestore().collection("users").doc(getters.getUserDataID).update({data:payload.data})
        }
      commit('setLoading',false) 
        
        
    },
  },
  getters: {
    getLoginMessage(state){
      return state.loginMessage
    },
    getLoginError(state){
      return state.loginError
    },
    getDebug(state){
        return state.debug
    },
    getExhibitionLoaded(state){
      return state.exhibitionLoaded
    },
    showSignIn(state){
      return state.showSignIn
    },
    getLoginNag(state){
      return state.loginNag
     },
    user(state) {
      return state.user
    },
    getUserIsLoaded(state) {
      return state.userIsLoaded
    },
    getUserData(state) {
      return state.userData || null
    },
    getUserDataID(state){
      return state.userDataID
    },
    userAdmin(state) {
      return state.admin
    },
    userCurator(state) {
      return state.curator
    },
    entryUrl(state){
      return state.entryUrl
    },
    getFireBaseLoc(state){
      return state.fireBaseLoc
    },
    

  },


}
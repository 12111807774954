export default {
  data() {
    return {
      allNames: [],
      firstDate: null,
      lastDate: null,
      numColumns: 4,
      numPeopleColumns: 5
    };
  },
  mounted() {
    this.sizeRatio = document.documentElement.clientWidth / 1920
    this.pageWidth = document.documentElement.clientWidth
    this.pageHeight = window.innerHeight
    window.addEventListener('resize', this.handleResize)
    if (!this.allContent) {
      this.$store.dispatch("getContent")
    } else {
    }
    if (!this.allPeople) {
      this.$store.dispatch("getPeople")
    }
    if (!this.allLocations) {
      this.$store.dispatch("getLocations")
    }
    this.handleResize()
  },
  computed: {
    sortType(){
      return this.$store.getters.getSortType
    },
    titleBG() {
      if (this.content && this.content.themes && this.content.themes.length > 0) {
        return " " + this.content.themes[0] + "BG"
      } else if (this.selectedContent && this.selectedContent.themes && this.selectedContent.themes.length > 0) {
        return " " + this.selectedContent.themes[0] + "BG"
      } else {
        return " BG"
      }
    },
    titleCol() {
      if (this.content && this.content.themes && this.content.themes.length > 0) {
        return " " + this.content.themes[0] + "Col"
      } else {
        return " Col"
      }
    },
    filteredContent() {
      var filtered = []
      var typeCheck = this.typeFilter
      var themeCheck = this.themeFilter
      var moveCheck = this.movementFilter
      var myCheck = this.myFilter
      console.log("FILTER CONTENT MOVE", moveCheck)
      if (themeCheck) {
        // themeCheck=themeCheck.toLowerCase()
      }
      if (typeCheck) {
        typeCheck = typeCheck.toLowerCase()
      }
      if (typeCheck == "documentaries") {
        typeCheck = "Documentary"
      }
      if (typeCheck == "long reads") {
        typeCheck = "longRead"
      }
      if (typeCheck == "short reads") {
        typeCheck = "shortRead"
      }
      for (var c in this.allContent) {
        let valid = true
        if (this.allContent[c].status && this.allContent[c].status != "Published") {
          valid = false
        }
        if (themeCheck != "All Themes" && !this.allContent[c].themes.includes(themeCheck)) {
          valid = false
        }
        if (moveCheck && this.allContent[c].movements != moveCheck) {
          valid = false
        }
        if (typeCheck && this.allContent[c].type != typeCheck) {
          valid = false
        }
        if (valid) {
          console.log(this.allContent[c])
          filtered.push(this.allContent[c])
        }
        if (myCheck == "Pinned") {
          console.log("Check Pinned", this.allContent[c].pinned)
          if (!this.allContent[c].pinned) {
            valid = false
          }
        }
      }
      console.log("PRE SORT",filtered[0])
      filtered=filtered.sort(this.titleSort)
      return filtered
    },
    titleSort(a, b) {
      console.log(a,b)
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    },
    searchQuery() {
      return this.$store.getters.getSearchQuery
    },
    reShuffle() {
      return this.$store.getters.getReshuffle
    },
    themeFilter() {
      return this.$store.getters.getThemeFilter
    },
    movementFilter() {
      return this.$store.getters.getMovementFilter
    },
    myFilter() {
      console.log("GET FILTER", this.$store.getters.getMyFilter)
      return this.$store.getters.getMyFilter
    },
    typeFilter() {
      return this.$store.getters.getTypeFilter
    },
    shortReads() {
      if (this.allContent) {
        return this.allContent.filter(read => read.type == "shortRead")
      } else {
        return null
      }
    },
    longReads() {
      if (this.allContent) {
        return this.allContent.filter(read => read.type == "longRead")
      } else {
        return null
      }
    },
    allContent() {
      let cont = this.$store.getters.getContent
      if (cont && cont.length > 0) {
     
        return cont
      }
     
     
      return null
    },
    personalContent() {
      let userData = this.$store.getters.getUserData
      let pContent = this.allContent
      let cont
      if (userData.data && pContent) {
        console.log("CHECK P")
        for (var c in pContent) {
          cont = pContent[c]
          pContent[c].viewed = false
          pContent[c].viewState = "unviewed"
          pContent[c].pinned = 0
          pContent[c].favorite = false
          pContent[c].favoriteState = "non-favorite"
          for (var p in userData.data.pinned) {
            if (cont.id.indexOf(userData.data.pinned[p].id) >= 0) {
              pContent[c].pinned = userData.data.pinned[p].index
            }
          }
          for (var v in userData.data.viewed) {
            if (cont.id.indexOf(userData.data.viewed[v]) >= 0) {
              pContent[c].viewed = true
              pContent[c].viewState = "viewed"
            }
          }
          for (var l in userData.data.liked) {
            if (cont.id.indexOf(userData.data.liked[l]) >= 0) {
              pContent[c].favorite = true
              pContent[c].favoriteState = "favorite"
            }
          }
        }
        return pContent
      }
    },
    allPeople() {
      return this.$store.getters.getPeople || null
    },
    allLocations() {
      return this.$store.getters.getLocations || null
    },
    allDocumentries() {
      return this.allContent.filter(cont => cont.type == "Documentary");
    },
    allMovements() {
      return this.$store.getters.getMovements || null
    },
    selectedContent() {
      return this.$store.getters.getSelectedContent || null
    },
    firebasePath() {
      return this.$store.getters.getFirebasePath
    },
    webPath() {
      return this.$store.getters.getEditorPath
    },
  },
  methods: {
   
    iconBG(content) {
      if (content.themes && content.themes.length > 0) {
        return " " + content.themes[0] + "BG"
      } else {
        return " BG"
      }
    },
    resetFilters() {
      this.$store.commit("setSearchQuery", null)
    },
    assContent(cont) {
      let assCont = []
      let meanwhile = []
      let nearBy = []
      let ccont
      for (var c in this.allContent) {
        ccont = this.allContent[c]
        for (var l in cont.linkedContent) {
          if (ccont.id.indexOf(cont.linkedContent[l]) > -1) {
            if (ccont.images && ccont.images.length > 0 && ccont.images[0] != "") {
              assCont.push(ccont)
            }
          }
        }
        if (ccont.keyDate && cont.keyDate && ccont.id != cont.id) {
          if (Math.floor((Math.abs(cont.keyDate - ccont.keyDate) / 60 / 60 / 24)) < 190) {
            if (ccont.images && ccont.images.length > 0 && ccont.images[0] != "") {
              meanwhile.push(ccont)
            }
          }
        }
        if (ccont.locations && cont.locations && ccont.id != cont.id) {
          if (ccont.locations[0] == cont.locations[0]) {
            if (ccont.images && ccont.images.length > 0 && ccont.images[0] != "") {
              nearBy.push(ccont)
            }
          } else {
          }
        }
      }
      return { links: assCont, nearby: nearBy, meanwhile: meanwhile }
    },
    getItemByID(id) {
      return this.$store.getters.getContentByID(id)
    },
    getItem(contID) {
      return this.getItemByID(contID)
    },
    parseDateString(dateStr) {
      dateStr = "" + dateStr
      console.log("Parse Date String", dateStr)
      let date = dateStr.split(" ")
      let tmp
      let sec = 0
      if (date.length == 3) {
        tmp = new Date(dateStr)
      } else if (date.length == 2) {
        tmp = new Date(1 + " " + date[0] + " " + date[1])
        sec = 1
      } else if (date.length == 1) {
        tmp = new Date(1 + " Jan " + date[0])
        sec = 2
      } else {
        console.log("DATE OF Unknown")
        return null
      }
      tmp.setSeconds(sec)
      console.log("DATE OF ", tmp)
      return tmp
    },
    parseDate(date, MY) {
      console.log("TRY Date error",date, MY)
      if (date) {
        try {
          date = date.toDate()
          console.log("parseDateD", date, date.getSeconds())
        }
        catch (err) {
          console.log("Parse Date error",err)
          return date
        }
     
      let year = date.getFullYear()
      let month = date.toLocaleString('default', { month: 'short' });
      let day = date.getDate()
      let sec = date.getSeconds()
      console.log("year",year,month,day,sec)
      if (sec == 1) {
        return month + " " + year
      }
      if (sec == 2) {
        return year
      }
      if (year == 1000) {
        return "UNKNOWN"
      }
      if (day == 1 && month == "Jan") {
        if (year == 1000) {
          return "UNKNOWN"
        } else {
          // return year
        }
      }

      if (MY) {
        return month + " " + year
      }
      return day + " " + month + " " + year
    }else{
      return "UNKNOWN"
    }
    },
    contentDate(cont) {
      if (cont.keyDate) {
        return "[" + this.parseDate(cont.keyDate, true) + "]"
      } else if (cont.start && cont.end) {
        return "[" + cont.start + " - " + cont.end + "]"
      }
    },
    contentTitle(cont) {
      if (cont.type == "person") {
        return cont.displayName
        // return cont.displayName + " [" + this.parseDate(cont.dateOfBirth) + " - " + this.parseDate(cont.dateOfDeath) + "]"
      } else {
        return cont.title || cont.name
      }
    },
    shuffleArray(array) {
      let curId = array.length;
      // There remain elements to shuffle
      while (0 !== curId) {
        // Pick a remaining element
        let randId = Math.floor(Math.random() * curId);
        curId -= 1;
        // Swap it with the current element.
        let tmp = array[curId];
        array[curId] = array[randId];
        array[randId] = tmp;
      }
      return array;
    },
    imagePath(image) {
      return this.firebasePath + 'content%2F' + this.swapSpaces(image) + '?alt=media'
    },
    bgImageAlignment(alignment){
      if(alignment){
        if(alignment.indexOf("%")){
          return " background-position:50% "+alignment+";"
        }else{
          return " background-position:center "+alignment+";"
        }
       
      }else{
        return ""
      }
    },
    imageAlignment(alignment){
      if(alignment){
        if(alignment.indexOf("%")){
          return " object-position:50% "+alignment+";"
        }else{
          return " object-position:center "+alignment+";"
        }
       
      }else{
        return ""
      }
    },
    themeFix(theme){
      if(theme=="Unrest"){
        return "Repression"
      }if(theme=="Punishment"){
        return "Exploitation"
      }if(theme=="Dissent"){
        return "Resistance"
      }if(theme=="Activism"){
        return "Redemption"
      }
      return theme
    },
    imagePathPeople(image) {
      return this.firebasePath + 'people%2F' + this.swapSpaces(image) + '?alt=media'
    },
    themeColor(cont) {
      if (cont.themes && cont.themes.length > 0) {
        if (Array.isArray(cont.themes)) {
          return " " + cont.themes[0] + "BG"
        } else {
          return " " + cont.themes + "BG"
        }
      } else {
        return " missingBG"
      }
    },
    activeTileClass(cont) {
      if (this.selectedTile) {
        if (this.selectedTile.id == cont.id) {
          return ""
        } else {
          return "inactive"
        }
      } else {
        return ""
      }
    },
    idCode(id) {
      if (id) {
        let idA = id.split("_")
        return idA[idA.length - 2] + "_" + idA[idA.length - 1]
      }
      return null
    },
    showArticle(cont) {
      this.showTheArticle(this.article(cont))
    },
    showTheArticle(article) {
      console.log("ShowTheArticle", article)
      this.showContent(article)
      let id = this.idCode(article.id)
      if (this.userData) {
        if (this.userData.viewed) {
          if (this.userData.viewed.includes(id)) {
            
          } else {
            this.userData.viewed.push(id)
          }
        } else {
          this.userData.viewed = [id]
        }
        this.$store.dispatch("updateUserData", { type: "viewed", data: this.userData })
      }
    },
    setCharNames() {
      this.allNames = []
      for (var p in this.allPeople) {
        this.allNames.push(this.allPeople[p].displayName)
      }
    },
    showContent(article) {
      this.$store.commit("setShowDocumentary", null)
      this.$store.commit("setShowLongRead", null)
      this.$store.commit("setShowShortRead", null)
      this.$store.commit("setShowObject", null)
      if (article.type == "shortRead") {
        this.$store.commit("setShowShortRead", article)
      } else if (article.type == "longRead") {
        this.$store.commit("setShowShortRead", article)
      } else if (article.type == "Documentary") {
        this.$store.commit("setShowDocumentary", article)
      }else if (article.type == "object") {
        this.$store.commit("setShowObject", article)
      }  else {
        this.$store.commit("setShowShortRead", article)
        if (!article.title) {
          article.title = article.name
        }
      }
    },
    hidePop() {
      this.$store.commit("setShowShortRead", null)
      this.$store.commit("setShowDocumentary", null)
      this.$store.commit("setShowObject", null)
      this.$store.commit("setShowLongRead", null)
      this.$store.commit("setShowPerson", null)
    },
    tileBG(cont) {
      if (cont.themes && cont.themes.length > 0) {
        return " " + cont.themes[0] + "BG"
      } else {
        return " missingBG"
      }
    },
    tileIcon(cont) {
      let type =cont.type
      if(type.toLowerCase()=="short read"){
        type="ShortRead"
      }
      if (type) {
        
        return type.toLowerCase()
      } else {
        return " ShortRead"
      }
    },
    tileClass(cont) {
      let tClass = "ShortRead"
      if (cont.type) {
        tClass = cont.type.toLowerCase()
      } else {
        tClass = tClass + " DissentBG"
      }
      if (cont.themes && cont.themes.length > 0) {
        tClass = tClass + " " + cont.themes[0] + "BG"
      } else {
        tClass = tClass + " missingBG"
      }
      return tClass
    },
    labelTheme(str) {
      if (Array.isArray(str)) {
        str = str[0]
      } else if (str) {
        str = str.split(",")[0]
      }
      return str + "BG"
    },
    swapSpaces(image) {
      return escape(image)
    },
    itemType(str,song) {
      console.log(str,song)
      switch (str) {
        case "shortRead":
          return "Short Read"
        case "longRead":
          return "Long Read"
        case "LongRead":
          return "Long Read"
        case "audio":
            if (song) {
              return "Song"
            }else{
              return "Audio"
            }
          case "Documentary":
            if (song) {
            return "Music Clip"
            }else{
              return "Documentary"
            }
        default:
          return str
      }
    },
    iconType(str) {
      return str.split(/\s+/).join('').toLowerCase()
    },
    titleIcon(cont) {
      return cont.type || "ShortRead"
    },
    scaled(val) {
      return val * this.pageWidth / 1920
    },
    handleResize(e) {
      this.sizeRatio = document.documentElement.clientWidth / 1920
      this.pageWidth = document.documentElement.clientWidth
      this.pageHeight = window.innerHeight
      this.numColumns = Math.floor(this.pageWidth / 300)
      this.numPeopleColumns = Math.floor(this.pageWidth / 200)
    },
    ratio(val) {
      return Math.floor(this.pageWidth / width * val)
    },
    triggerEventOnMousePause(item, eventToTrigger, delay = 500) {
      let timeoutId;
      item.addEventListener("mousemove", () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          item.dispatchEvent(new Event(eventToTrigger));
        }, delay);
      });
      item.addEventListener("mouseleave", () => {
        clearTimeout(timeoutId);
      });
    }
  },
 
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  }
}

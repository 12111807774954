<template>
  <div class="popup-container">
    <div class="popup">
      <MediaNavbar :content="content"></MediaNavbar>
      <div class="doco">
        <div class="iframe-container">
          <iframe v-if="content.video" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
            :src="'https://player.vimeo.com/video/' + vimeoDetails(content.video).id + '?badge=0&amp;h=' + vimeoDetails(content.video).appId + '&autopause=0&amp;player_id=0&amp;app_id=' + vimeoDetails(content.video).appId">
          </iframe>
        </div>
        <div v-if="!content.video" class="loading"></div>
        <div class="text">
          <h1>{{ contentTitle(content) }}</h1>
          <p v-html="content.content"></p>
        </div>
          <!-- <ExtraContent v-if="true" :content="content" style="width: 100%;"></ExtraContent> -->
      </div>
    </div>
  </div>
</template>

<script>
import MediaNavbar from '@/components/MediaNavbar.vue'
import ExtraContent from '@/components/ExtraContent.vue'
import contentMixin from '@/mixins/content.js'
export default {
  name: 'MediaDocumentary',
  components: { MediaNavbar, ExtraContent },
  mixins: [contentMixin],
  props: {
    content: { default: null }
  },
  computed: {
    firebasePath() {
      return this.$store.getters.getFirebasePath
    },
  },
  methods: {
    assItems(content) {
      let maxItems = 5
      let items = this.assContent(content)
      let links = items.links.length
      let meanwhile = items.meanwhile.length
      let nearby = items.nearby.length
      links = Math.min(links, maxItems - (Math.min(meanwhile, 1)) - Math.min(nearby, 1))
      let remaining = maxItems - links
      let remainingMax = remaining - nearby
      let remainingHalf = Math.max(remainingMax, Math.floor(remaining / 2))
      meanwhile = Math.min(remainingHalf, meanwhile)
      nearby = Math.min(nearby, maxItems - links - meanwhile)
      console.log(items, { links: links, meanwhile: meanwhile, nearby: nearby })
      return { links: links, meanwhile: meanwhile, nearby: nearby }
    },
    vimeoDetails(url) {
      url = url.split("/")
      return { id: url[3], appId: url[4] }
    },
    hasImage(images) {
      if (images && images[0]) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-container {
  width: 920px;
  margin: auto;
  margin-top: 6em;
  position: static;
  transition: 0.25s;
}
.popup {
  outline: var(--white) 2px solid;
  padding: 2px;
  margin-bottom: 6em;
  background-color: var(--black);
  filter: drop-shadow(0 0 2em var(--half-black));
  overflow: hidden;
}
.doco {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.iframe-container {
  position: relative;
  height: calc(100vh - 22.75em);
}
.text {
  text-align: left;
  padding: 1.5em 1.25em;
  margin-top: 2px;
  background-color: var(--grey);
  outline: 2px solid var(--black);
  overflow-y: scroll;
  flex-grow: 1;
  h1 {
    margin-bottom: 0.25em;
  }
}
@media only screen and (max-width: 660px) {
  .popup-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    margin-top: 0;
  }
  .popup {
    outline: none;
    padding: 0;
    filter: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
  }
  .iframe-container {
    height: calc(100vh - 32em);
  }
  .text {
    padding: 1.25em 1em;
    flex-grow: 1;
  }
}
</style>
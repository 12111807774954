import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'firebase/firestore';
import firebase from 'firebase/compat/app';


// createApp(App).use(store).use(router).mount('#app')

const app = createApp(App).use(store).use(router);
      const firebaseConfig = {
        apiKey: "AIzaSyBTWncDtH7ubGBtMMzXIKU9j9HlCFy1v0c",
        authDomain: "convictionpolitics-f7.firebaseapp.com",
        projectId: "convictionpolitics-f7",
        storageBucket: "convictionpolitics-f7.appspot.com",
        messagingSenderId: "200543160014",
        appId: "1:200543160014:web:9e3fa7ee165292668fb29f",
        measurementId: "G-TXR4RGBSCC"
      };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

app.mount('#app');
firebase.auth().onAuthStateChanged(user => {
  if (user) {
    store.dispatch('autoSignIn', user)
  }
})

 

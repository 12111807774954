
import { createStore } from 'vuex';
import paths from './paths'
import user from './user'
import content from './content'

export default createStore({
  modules: {
    paths:paths,
    content:content,
    user:user
  },
});
<template>
  <div style="text-align: left;" v-if="item.title">
    <!-- Eventually replace <LabelContent> with <ExtraContent> (See Conviction Politics 2024 XD)-->
    <div :class="'thumbnail-heading ' + labelTheme(item)">
      <h2>{{ item.title }}</h2>
    </div>
  </div>
</template>

<script>
import Content from '@/mixins/content'
export default {
  name: 'ContentLabel',
  mixins: [Content],
  props: {
    item: {
      type: Object,
      default: () => ({
        label: "Title",
        type: "TYPE",
        theme: "Unrest"
      })
    }
  },
  computed: {
    itemExtra() {
      if (this.item.duration) {
        return Math.floor(this.item.duration / 60) + "<span style='font-size: 0.75em;'>MIN</span>"
      } else {
        return ""
      }
    }
  },
  methods: {
    labelTheme(item) {
      if (item) {
        if (item.theme) {
          return item.theme + "BG"
        } else if (item.themes) {
          if (item.themes[0]) {
            return item.themes[0] + "BG"
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail-heading {
  display: flex;
  justify-content: left;
  width: fit-content;
  padding: 0.4em 0.4em 0 0.4em;
  color: var(--black);
  transition: 0.5s;
  margin: 0.25em 0;
}
.thumbnail-heading:hover {
  background-color: var(--white);
}
</style>
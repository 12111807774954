<template>
  <div v-if="showPerson" class="overlay">
    <MediaPerson :content="showPerson"></MediaPerson>
  </div>
  <div v-if="showShortRead" class="overlay">
    <MediaShortRead :content="showShortRead"></MediaShortRead>
  </div>
  <div v-if="showDocumentary" class="overlay">
    <MediaDocumentary :content="showDocumentary"></MediaDocumentary>
  </div>
  <div v-if="showObject" class="overlay">
    <MediaObject :content="showObject"></MediaObject>
  </div>
  <div v-if="showLongRead" class="overlay">
    <XMediaLongRead></XMediaLongRead>
  </div>
  <div v-if="showSignIn" class="overlay">
    <SignUp></SignUp>
  </div>
  <div v-if="showShare" class="overlay">
    <Sharing></Sharing>
  </div>
  <div v-if="showReorder" class="overlay">
    <Reorder></Reorder>
  </div>
  <router-view />
</template>

<script>
import MediaShortRead from '@/components/MediaShortRead.vue'
import XMediaLongRead from '@/components/XMediaLongRead.vue'
import MediaDocumentary from '@/components/MediaDocumentary.vue'
import MediaObject from '@/components/MediaObject.vue'
import MediaPerson from '@/components/MediaPerson.vue'
import Sharing from '@/components/Sharing.vue'
import SignUp from '@/components/SignUp.vue'
import Reorder from '@/components/Reorder.vue'

export default {
  name: 'Home',
  components: {
    MediaShortRead,
    XMediaLongRead,
    MediaDocumentary,
    MediaObject,
    MediaPerson,
    Sharing,
    SignUp,
    Reorder
  },
  data() {
    return {}
  },
  beforeCreate(){
       let webPath=window.location.href.substr(0,window.location.href.lastIndexOf("/"))
       this.$store.commit('setWebPath',webPath)
       function  getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }
       this.$store.commit('setEntryPath',getParameterByName("pathway"))
     },
  mounted() {
    this.$store.commit('setSharedPath',this.getParameterByName("sa"))   
  },
  computed: {
    hasPopUp() {
      if(this.showPerson || 
        this.showShortRead || 
        this.showDocumentary ||
        this.showLongRead
        ) {
          return true
        }
      return false
    },
    showPerson() {
      return this.$store.getters.getShowPerson
    },
    showShortRead() {
      return this.$store.getters.getShowShortRead
    },
    showDocumentary() {
      return this.$store.getters.getShowDocumentary
    },
    showObject() {
      return this.$store.getters.getShowObject
    },
    showLongRead() {
      return this.$store.getters.getShowLongRead
    },
    userData() {
      this.$store.getters.getUserData
    },
    showShare() {
      return this.$store.getters.getShowShare
    },
    showSignIn() {
      return this.$store.getters.showSignIn
    },
    showReorder() {
      return this.$store.getters.getShowReorder
    },
  },
  methods: {
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
  },
  watch: {
    hasPopUp(val) {
      if (val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  }
}
</script>

<style lang="scss">
@import './assets/variables.css';
@import './assets/conviction-politics-icons.css';
body {
  --lavender: #9999AA;
  --brown: #BBAA99;
  --white: #FFFFFF;
  --grey-light: #AAAAAA;
  --grey: #444444;
  --grey-dark: #222222;
  --black: #000000;
  --half-black: #00000088;
  margin: 0;
  font-size: 16px;
  color: var(--white);
  background-color: var(--black);
  height: 100%;
}
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.overlay {
  position: fixed;
  inset: 0;
  display: flex;
  background-color: var(--half-black);
  z-index: 100;
  overflow-y: scroll;
  pointer-events: auto;
  transition: 0.5s;
}
.loading {
  width: 100%;
  height: 100%;
  min-height: 20em;
  background: var(--black) url("./assets/images/icons/loading.gif") no-repeat center;
  background-size: 4em;
}
.UnrestBG { background-color: var(--unrest-color); }
.DissentBG { background-color: var(--dissent-color); }
.ActivismBG { background-color: var(--activism-color); }
.PunishBG { background-color: var(--punish-color); }
.PunishmentBG { background-color: var(--punish-color); }
.undefinedBG { background-color: var(--brown); }
.missingBG{ background-color: var(--brown);
    }

// Fonts
@font-face {
  font-family: "DinCondensed";
  src: url(assets/fonts/DinCondensedBold.woff2) format("woff2");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DDin";
  src: url(assets/fonts/DDinBold.woff2) format("woff2");
  font-style: normal;
  font-display: block;
}
h1 {
  font-family: "DinCondensed", Roboto, sans-serif;
  font-size: 2.2em;
  text-transform: uppercase;
  line-height: 1em;
  margin: 0;
}
h2 {
  font-family: "DinCondensed", Roboto, sans-serif;
  font-size: 1.4em;
  text-transform: uppercase;
  line-height: 1em;
  margin: 0;
}
h3 {
  font-family: "DinCondensed", Roboto, sans-serif;
  font-size: 1.1em;
  text-transform: uppercase;
  margin: 0;
}
p {
  font-family: "DDin", Roboto, sans-serif;
  font-size: 1em;
  line-height: 1.3em;
  margin: 0;
}
a {
  color: var(--white);
  text-decoration: none;
}

// Old Fonts
@font-face {
  font-family: "ScratchXBlack";
  src: local("ScratchXBlack"), url(assets/fonts/ScratchXBlack.ttf) format("truetype");
}
@font-face {
  font-family: 'conviction-politics-icons';
  src: url(assets/fonts/conviction-politics-icons.ttf) format('truetype')
}
</style>
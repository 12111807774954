<template>
  <div class="popup-container">
    <div class="popup">

      <div style="display: flex; margin-bottom: 2.5em; background-color: var(--brown);">
        <div class="content-navbar">
          <div class="category-icon icon-share"></div>
          <h2 class="category-text">SHARE CONTENT</h2>
        </div>
        <div class="icon-box">
          <div @click="closePopup" class="icon-close icon"></div>
        </div>
      </div>

      <div class="shared-item">
        <img class="logo" src="../assets/conviction-politics-icon.svg">
        <h1 v-if="numArticles > 1">{{ numArticles }} Articles</h1>
        <h1 v-else>{{ selectedContent }}</h1>
      </div>
      <div class="shared-link">
        <input id="pageLink" :value="webPath + '/stories?sa=' + currentArticles" readonly="readonly">
        <div style="display: flex; justify-content: center; gap: 2px;">
          <button v-if="canShare" class="link-button" icon="link" @click="shareLink(selectedContent)">
            <div class="icon-share"></div>
            <h2>SHARE</h2>
          </button>
          <button class="link-button" icon="link" @click="copyLink">
            <div class="icon-link"></div>
            <h2>COPY LINK</h2>
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Sharing',
  computed: {
    canShare() {
      return navigator.share
    },
    selectedContent() {
      if (this.currentArticles && this.$store.getters.getContentByID(this.currentArticles)["title"]) {
        return this.$store.getters.getContentByID(this.currentArticles)["title"]
      }
    },
    webPath() {
      return this.$store.getters.getWebPath
    },
    currentArticles() {
      return this.$store.getters.getCurrentArticles
    },
    numArticles() {
      return this.currentArticles.split(",").length
    },
    isTester() {
      return this.$store.getters.getIsTester
    },
    debug() {
      return this.$store.getters.getDebug
    },
    userIsLoaded() {
      return this.$store.getters.getUserIsLoaded
    },
    userData() {
      return this.$store.getters.getUserData
    },
  },
  methods: {
    closePopup() {
      this.$store.commit('setShowShare', false)
    },
    shareLink(title) {
      if (navigator.share) {
        navigator.share({
          title: title,
          url: document.getElementById("pageLink")
        })
          .then(() => console.log('Link shared successfully!'))
          .catch(error => console.error('Error sharing link:', error));
      } else {
        console.log('Web Share API not supported');
      }
    },
    copyLink() {
      var copyText = document.getElementById("pageLink");
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      /* Copy the text inside the text field */
      //navigator.clipboard.writeText(copyText.value);
      try {
        var retVal = document.execCommand("copy");
        console.log('Copy to clipboard returns: ' + retVal);
      } catch (err) { console.log('Error while copying to clipboard: ' + err); }
      alert("Copied the link to your clipboard\n");
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-container {
  width: 600px;
  margin: auto;
  position: static;
  transition: 0.25s;
}
.popup {
  outline: var(--white) 2px solid;
  padding: 2px;
  background-color: var(--black);
  filter: drop-shadow(0 0 2em var(--half-black));
  overflow: hidden;
}
.content-navbar {
  display: flex;
  flex-grow: 1;
  background: linear-gradient(to right, var(--black) 0%, transparent 100%);
}
.category-text {
  padding: 0.5em 0 0.2em;
}
.category-icon {
  font-size: 1.5em;
  padding: 0.25em 0.25em 0;
}
.logo {
  width: 3.5em;
  margin: -0.75em 1em 0 0;
}
.icon-box {
  display: flex;
  height: 100%;
}
.icon {
  width: 2em;
  font-size: 1.5em;
  line-height: 1.6em;
  color: var(--black);
  background-color: transparent;
  transition: 0.25s;
}
.icon:hover, .active {
  color: var(--black);
  background-color: var(--white);
}
.shared-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em;
  h1 {
    text-align: left;
    max-width: 400px;
  }
}
.shared-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2em;
}
button {
  width: 12em;
  color: var(--black);
  background-color: var(--brown);
  padding: 1em 2em 0.5em;
  border: none;
  transition: 0.1s;
  display: flex;
  justify-content: center;
  margin-top: 1em;
  .icon-link, .icon-share {
    font-size: 1.5em;
    margin-top: -0.25em;
    margin-left: -0.25em;
    margin-right: 0.25em;
  }
}
button:hover {
  background-color: var(--white);
}
input {
  font-family: "DDin", Roboto, sans-serif;
  font-size: 1em;
  line-height: 1.3em;
  margin: 0;
  border-radius: 0.25em;
  outline: 1px var(--brown) solid !important;
  color: var(--brown) !important;
  background-color: var(--grey) !important;
  width: 100% !important;
  height: 2em !important;
  text-align: center !important;
}
</style>
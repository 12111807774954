import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import AuthGuard from './auth-guard'
import AdminGuard from './admin-guard'

const routes = [
  { path: '/',        name: 'home',    component: Home },
  { path: '/stories', name: 'stories', component: () => import('../views/Stories.vue') },
  { path: '/people',  name: 'people',  component: () => import('../views/People.vue') },
  { path: '/time',    name: 'time',    component: () => import('../views/Time.vue') },
  { path: '/place',   name: 'place',   component: () => import('../views/Place.vue') },
  { path: '/index',   name: 'index',   component: () => import('../views/Index.vue') },
  { path: '/credits', name: 'credits', component: () => import('../views/Credits.vue') },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router